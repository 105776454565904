import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, {useContext, useEffect} from 'react';
import useState from 'react-usestateref';
import {openErrorModal} from '@/components/form/ByzzerModal';
import {useTenantApi} from '@/hooks/useTenantApi';
import {useAccountService} from '@/hooks/useAccountService';
import * as ProductService from "@/services/product.service";
import {ByzzerSelectOption} from '@byzzer/ui-components';
import {CharacteristicCondition, RunConfigMarket, TimePeriod} from "@/types/ReportRun";
import {PPG, setAllPpgs} from '@/services/ppg.service';
import {ByzzerLink} from '@/components/form';
import {useNavigate} from 'react-router-dom';
import {QuoteResponseType} from '@/types/QuoteTypes';
import {SimulatorDataset} from '@/types/SimulatorTypes';
import {DefaultRunConfig, DodPresetMap, PersonaDetails, ProductFilterOption, SubscriptionProduct, TenantCompany, TenantType, TenantUser, UserFavorites} from '@/types/ApiTypes';
import {useSharedApi} from '@/hooks/useSharedApi';
import {cloneDeep} from 'lodash';
import {DodExcelTemplate} from "@/types/DodRun";
import { NotificationEvent } from '@/types/EventTypes';
import { CsrAccessRoles } from '@/types/CsrOboTypes';
import { UserAlertSummary } from '@/types/AlertRun';


// todo: define types for messages and events
export type AppContextValue = {
    tenantId?: string;
    availableTenants: TenantType[];
    changeTenant(tenantId: string | undefined): void;
    loadingTenantData?: boolean;
    allProducts: ProductWithMeta[];
    allDatasets: SimulatorDataset[],
    categories: string[];
    setCategories(categories: string[]): void;
    categoriesEditable: boolean;
    brandNotRetailingYet?: boolean;
    brands?: string[];
    company?: TenantCompany; // need to create defined type for this (might already exist)
    userQuote?: QuoteResponseType;
    unacceptedCompanyQuoteId?: number;
    customCharacteristics: CustomCharacteristic[];
    defaultRunConfig?: DefaultRunConfig;
    demographicOptions: ByzzerSelectOption[];
    events: NotificationEvent[];
    favorites: UserFavorites;
    features: Record<string, any>;
    initialized: boolean;
    isCsrObo: boolean;
    oboRole: string | null;
    csrOboAccessRoles: CsrAccessRoles[];
    maxDataDates: MaxDataDates;
    messages: any[];
    nsCompany?: any;
    omniCategories: string[];
    ppgs: any[];
    hierarchyTree?: any,
    refreshUserQuote: (quote?) => void;
    refreshConfiguredAlerts: (configuredAlerts?: UserAlertSummary[]) => Promise<void>;
    refreshUser(newUserData?: Partial<TenantUser>): Promise<void>;
    refreshCompanyBrandInfo: (brandInfo: {brands?: string[], brandNotRetailingYet?: boolean, categories?: string[]}) => void;
    reloadSubscription: () =>  Promise<void>;
    refreshDefaultRunConfig: (newDefaultRunConfig?: DefaultRunConfig) => Promise<void>;
    reloadDownloadableProducts?: () => Promise<void>;
    subscribedSkus: string[];
    subscription?: SubscriptionProduct | null;
    // todo: define a proper type for user
    user?: TenantUser | null;
    admins: any[];
    reportFilterOptions: ProductFilterOption[];
    downloadableProducts: any[] | null;
    refreshNotifications: () => Promise<void>;
    reloadDodPresets: () => Promise<void>;
    notifications: any[];
    activeNotifications: any[];
    refreshAccount: () => Promise<void>;
    isMultiTenant: boolean;
    dodExcelTemplates: DodExcelTemplate[];
    dodPresets: DodPresetMap;
    factConfig: any;
    teamMembers: any[];
    allMarkets: MarketNode[];
    canEditCategories?: boolean;
    preSales: boolean | undefined;
    viewedTips: string[];
    markTipViewed(key: string): void;
    accessibleMasterCompanies: string[];
    oboAllowAccessWhenTcsPending?: boolean;
    updateOboAllowAccess: (oboFlag?) => void;
    configuredAlerts: UserAlertSummary[];
    personas?: PersonaDetails[];
    runConfigUpcDescription?: {
        text: string;
        value: string;
    }[];
}
export const AppContext = React.createContext<AppContextValue>({
    messages: [],
    events: []
} as any);

export const useEvents = () => useContext(AppContext).events;
export const useMessages = () => useContext(AppContext).messages;
export const useUser = () => useContext(AppContext);
export const useApp = () => useContext(AppContext);

export const AppProvider = ({children}) => {
    const {
        setTenantApiHeader,
        getCategoryHierarchyTree,
        getDefaultRunConfig,
        getFullAccount,
        getMySubscription,
        getMyDownloadableProducts,
        getMyNotifications,
        getAvailableTenants,
        getDodExcelTemplates,
        getFactConfig,
        getTeamMembers,
        getDodPresets,
        markTipViewed: markTipViewedApi,
        getConfiguredAlerts,
        getUpcDescriptionByCode
    } = useTenantApi();
    const {
        getAllFilterOptions,
        getAllDatasets,
        getAllProducts,
        getAllMarkets,
        getAllDemographicOptions,
        getAllPersonas
    } = useSharedApi();

    const [appState, setAppState, appStateRef] = useState<AppContextValue>({
        isMultiTenant: false,
        availableTenants: [],
        tenantId: undefined,
        loadingTenantData: false,
        changeTenant,
        allProducts: [],
        allDatasets: [],
        features: {},
        categories: [],
        company: undefined,
        userQuote: undefined,
        unacceptedCompanyQuoteId: undefined,
        omniCategories: [],
        categoriesEditable: false,
        brands: [],
        brandNotRetailingYet: undefined,
        demographicOptions: [],
        events: [],
        initialized: false,
        isCsrObo: false,
        oboRole: null,
        csrOboAccessRoles: [],
        maxDataDates: {},
        messages: [],
        ppgs: [],
        customCharacteristics: [],
        subscribedSkus: [],
        hierarchyTree: {},
        subscription: undefined,
        downloadableProducts: [],
        user: undefined,
        favorites: {
            subscription: [],
            adhoc: [],
            alerts: [],
            dod: []
        },
        refreshUserQuote,
        reloadSubscription,
        refreshConfiguredAlerts,
        refreshUser,
        refreshCompanyBrandInfo,
        refreshDefaultRunConfig,
        setCategories,
        admins: [],
        reportFilterOptions: [],
        reloadDownloadableProducts,
        refreshNotifications,
        notifications: [],
        activeNotifications: [],
        dodPresets: {} as any,
        dodExcelTemplates: [],
        reloadDodPresets,
        refreshAccount,
        factConfig: {},
        teamMembers: [],
        allMarkets: [],
        preSales: false,
        canEditCategories: false,
        viewedTips: [],
        markTipViewed,
        accessibleMasterCompanies: [],
        oboAllowAccessWhenTcsPending: false,
        updateOboAllowAccess,
        configuredAlerts: [],
        runConfigUpcDescription: []
    })

    const navigate = useNavigate();
    const {
        getUser,
        clearOboUserFromRequestHeaders,
        clearOboUserInfo,
        getIsCsrObo,
        setOboUserInRequestHeaders,
        getAccount,
        setAccountServiceHeader
    } = useAccountService();

    useEffect(() => {

        if (appState) {
            appState.events?.map(handleEvent);
        }
    }, [appState.events]);

    async function markTipViewed(key: string): Promise<string[]> {

        setAppState(({viewedTips, ...state}) => ({
            ...state,
            viewedTips: [...viewedTips, key]
        }));
        return markTipViewedApi(key);
    }

    function handleEvent(event: NotificationEvent) {

        switch (event?.type) {
            case 'categories_updated': {
                const body: { categories: string[] } = event.body;
                setAppState(appState => ({
                    ...appState,
                    categories: body.categories
                }));
                break;
            }
            case 'ppgs_updated': {
                const body: { ppgs: PPG[] } = event.body;
                const ppgs = body.ppgs;
                setAppState(appState => ({
                    ...appState,
                    ppgs
                }));
                setAllPpgs(ppgs);
                break;
            }
            case 'customchars_updated': {
                const body: { customCharacteristics: CustomCharacteristic[] } = event.body;
                setAppState(appState => ({
                    ...appState,
                    customCharacteristics: body.customCharacteristics
                }));
                break;
            }
            case 'reset_password_flag_updated': {
                const body: { requirePasswordReset: boolean } = event.body;
                setAppState(appState => ({
                    ...appState,
                    user: {
                        ...appState.user!,
                        requirePasswordReset: body.requirePasswordReset
                    }
                }));
                break;
            }
            case 'download_products_added':
                reloadDownloadableProducts();
                break;
            case 'contract_items_changed':
                reloadSubscription();
                break;
            case 'email_verified': {
                const body: {
                    user: { 
                        emailVerified: boolean;
                        tcsAccepted: boolean;
                        tcsAcceptedDtm: Date;
                    },
                    tenantId: string;
                } = event?.body;

                const {emailVerified, tcsAccepted, tcsAcceptedDtm} = body.user;
                const tenantId = body.tenantId;

                // @ts-ignore
                setAppState((state) => ({
                    ...state,
                    tenantId,
                    user: {
                        ...state.user,
                        emailVerified,
                        tcsAccepted,
                        tcsAcceptedDtm
                    },
                    loadingTenantData: true
                }))
                break;
            }
            case 'demo_verified': {
                const body: {
                    user: { 
                        emailVerified: boolean;
                    },
                    tenantId: string;
                } = event?.body;

                const {emailVerified} = body.user;
                const tenantId = body.tenantId;

                // @ts-ignore
                setAppState((state) => ({
                    ...state,
                    tenantId,
                    user: {
                        ...state.user,
                        emailVerified
                    },
                }))
                break;
            }              
            default:
                break;
        }
    }

    const handleLoginClick = async (firebaseUser) => {
        if (firebaseUser) {
            await firebase.auth().signOut()
        }
        navigate('/auth/sign_in', {replace: true});
    }

    let unsubscribeFromCommonEvents;
    let unsubscribeFromEmailEvents;
    let unsubscribeFromTenantEvents;
    let unsubscribeFromTenantMessages;

    useEffect(() => {
        ;(async () => {
            await loadSharedData();

            const unsubscribeToAuthChanges = firebase.auth().onAuthStateChanged(async (firebaseUser) => {

                let tenantId = localStorage.getItem('tenantId');

                if (firebaseUser) { // logged in
                    unsubscribeFromCommonEvents = subscribeToCommonEvents();
                    unsubscribeFromEmailEvents = subscribeToEmailEvents();
                    // subscribeToCommonMessages();
                    try {
                        // the csr obo need to be configured before any api is triggered
                        // otherwise the necessary header required for obo mode will not be set
                        // and the api will try to find byzzer-user with csr email
                        // and throws a access_denied error
                        const isCsrObo = await configCsrObo();

                        if (!isCsrObo && firebaseUser.emailVerified === false) {
                            markAppInitialized();
                            return;
                        }

                        const availableTenants = await getAvailableTenants();

                        if (tenantId && !availableTenants?.map(({id}) => id)?.includes(tenantId)) {
                            localStorage.removeItem('tenantId')
                            tenantId = null;
                        }

                        const isMultiTenant = Boolean(availableTenants?.length > 1);

                        if (!isMultiTenant) {
                            tenantId = availableTenants[0]?.id;
                            localStorage.setItem('tenantId', tenantId);
                        }

                        setAppState((state) => ({
                            ...state,
                            availableTenants,
                            isMultiTenant,
                            ...(tenantId ? {tenantId, loadingTenantData: true} : {})
                        }));

                        if (isMultiTenant && !tenantId) {  // if there are available tenants and no tenantId, then we need to prompt the user to select a tenant
                            markAppInitialized()
                        }
                    } catch (err: any) {
                        switch (err.response?.data?.code ?? err.code) {
                            case 'auth_user_not_found':
                                // @ts-ignore
                                await openErrorModal({
                                    title: `Session Has Expired`,
                                    content: (
                                        <>
                                            <p>Your session seems to have expired</p>
                                            {/* @ts-ignore */}
                                            <div>Please <ByzzerLink
                                                onClick={() => handleLoginClick(firebaseUser)}>login</ByzzerLink> again.
                                            </div>
                                        </>
                                    ),
                                });
                                //TODO: Should the user be deleted from authentication in this case?
                                await firebase.auth().signOut();
                                break;
                            case 'user_not_found':
                                // @ts-ignore
                                await openErrorModal({
                                    title: `Account Not Found`,
                                    content: (
                                        <>
                                            <p>We didn't find an account matching your email address.</p>
                                            <p>Please make sure you entered it correctly.</p>
                                        </>
                                    ),
                                });
                                //TODO: Should the user be deleted from authentication in this case?
                                await firebase.auth().signOut();
                                break;
                            case 'impersonation_disabled':
                                // @ts-ignore
                                await openErrorModal({
                                    title: `OBO disabled`,
                                    content: (
                                        <>
                                            <p>OBO is disable for this company due to their NielsenIQ contract.</p>
                                            <p>Please reach out to customer support for assistance.</p>
                                        </>
                                    ),
                                });
                                await firebase.auth().signOut();
                                break;
                            case 'network_error':
                                await openErrorModal({
                                    title: `Communication Error`,
                                    content: (
                                        <>
                                            <p>We can't reach the Byzzer servers at this time.</p>
                                            <p>Please make sure you're connected to the internet.</p>
                                        </>
                                    ),
                                    // @ts-ignore
                                    closeButtonText: 'Try Again',
                                });
                                return window.location.reload();
                            case 'unverified_byzzer_user':
                                // This error is thrown in OBO mode when the byzzer user is not verified
                                // @ts-ignore
                                await openErrorModal({
                                    title: `User Email Not Verified`,
                                    content: (
                                        <>
                                            <p>The user hasn't verified their email yet</p>
                                        </>
                                    ),
                                });
                                await firebase.auth().signOut();
                                break;
                            case 'maintenance_mode':
                                break;
                            default:
                                console.log('error', err);
                                await openErrorModal({
                                    title: `Unexpected Error`,
                                    content: (
                                        <>
                                            <p>Fear not our engineering team is on the job.</p>
                                        </>
                                    ),
                                    errorId: err.id
                                });
                                //TODO: Should the user be deleted from authentication in this case?
                                await firebase.auth().signOut();
                                break;
                        }
                    }
                } else {
                    setAppState((currAppState) => ({
                        ...currAppState,
                        availableTenants: [],
                        isMultiTenant: false,
                        tenantId: undefined,
                        user: null,
                        subscription: null,
                        company: undefined,
                        // resetting csr obo info
                        isCsrObo: false,
                        csrOboAccessRoles: [],
                        oboAllowAccessWhenTcsPending: false,
                        oboRole: null,
                    }));
                    localStorage.removeItem('tenantId');
                    unsubscribeFromCommonEvents?.();
                    unsubscribeFromEmailEvents?.();
                    unsubscribeFromTenantEvents?.();
                    markAppInitialized(); // if no firebase user and no available tenants, then we need to enable the app to show the login screen
                }
                return unsubscribeToAuthChanges;

            });
        })()
    }, []);

    function setTenantIdHeaders(tenantId: string) {
        setTenantApiHeader(tenantId);
        setAccountServiceHeader(tenantId);
    }

    useEffect(() => {
        ;(async () => {
            if (appState?.tenantId) {
                const {tenantId} = appState;
                setTenantIdHeaders(tenantId);
                try {
                    setAppState(state => ({
                        ...state,
                        loadingTenantData: true,
                    }));
                    await loadTenantData(tenantId);
                } catch (err: any) {
                    console.log('error loading user data', err);
                        switch(err.response?.data?.code ?? err.code) {
                            case 'data_maintenance_mode':
                                    break;
                            default:   
                                await openErrorModal({
                                    title: `Unexpected Error`,
                                    content: (
                                        <>
                                            <p>Fear not our engineering team is on the job.</p>
                                        </>
                                    ),
                                    errorId: err.id
                                });
                                await firebase.auth().signOut();
                        }
                } finally {
                    setAppState((state) => ({
                        ...state,
                        loadingTenantData: false,
                    }));
                    markAppInitialized()
                }
            }
        })()
    }, [appState?.tenantId]);

    async function loadTenantData(tenantId: string): Promise<void> {

        if (tenantId) {
            await loadFullAccountInfo(tenantId);

            const [
                hierarchyTree,
                dodExcelTemplates,
                factConfig,
                teamMembers
            ] = await Promise.all([
                getCategoryHierarchyTree(),
                getDodExcelTemplates(),
                getFactConfig(),
                getTeamMembers()
            ]);

            // todo: move this to a service, it doesn't belong here
            const factConfigTemp = cloneDeep(factConfig)
            const shareFactConfigTemp = cloneDeep(factConfig)
            // && val2?.allowedFacts.some((v) => [1, 2, 11, 4].includes(v))
            const coreFactConfig = factConfigTemp.filters.map((val, index) => {
                val.factLists = val.factLists?.map((val1, index1) => {
                    const findFact = factConfigTemp.facts.find(val2 => val2.isEnabled && val2.allowedFeatures?.includes("DoD") && val2.id == val1 && val2?.allowedFacts.some((v) => [1, 2, 11, 4].includes(v)))
                    if (findFact) {
                        return {
                            ...findFact,
                            key: val?.key
                        }
                    }
                }).filter(Boolean)
                val.type = `core-${val.key}`
                return val;
            }).sort((a, b) => a.key - b.key)

            // && val2?.allowedFacts.some((v) => [5, 6, 7, 8, 9, 10].includes(v))
            const shareFactConfig = shareFactConfigTemp.filters.map((val, index) => {
                val.factLists = val.factLists?.map((val1, index1) => {
                    const findFact = shareFactConfigTemp.facts.find(val2 => val2.isEnabled && val2.allowedFeatures?.includes("DoD") && val2.id == val1 && val2?.allowedFacts.some((v) => [5, 6, 7, 8, 9, 10].includes(v)));
                    if (findFact) {
                        return {
                            ...findFact,
                            key: val?.key
                        }
                    }
                }).filter(Boolean)
                val.type = `share-${val.key}`
                return val;
            }).sort((a, b) => a.key - b.key)

            setAppState(state => ({
                ...state,
                // todo: do we need to recalculate this on category change events (yes)
                hierarchyTree,
                dodExcelTemplates,
                factConfig: {
                    core: coreFactConfig,
                    share: shareFactConfig
                },
                teamMembers,
            }));
        } else {
            // messages is WIP for multi-tenant support
            // unsubscribeFromTenantMessages?.();
            unsubscribeFromTenantEvents?.();
        }

    }

    async function loadFullAccountInfo(tenantId: string) {
        if(!tenantId) {
            return
        }
        const [
            {
                maxDataDates,
                categories,
                omniCategories,
                ppgs,
                user,
                company,
                userQuote,
                features,
                defaultRunConfig,
                subscribedSkus,
                subscription,
                favorites,
                nsCompany,
                customCharacteristics,
                categoriesEditable,
                admins,
                downloadableProducts,
                brands,
                brandNotRetailingYet,
                canEditCategories,
                preSales,
                viewedTips = [],
                extractPresets,
                accessibleMasterCompanies = [],
                unacceptedCompanyQuoteId
            }, 
            configuredAlerts
        ] = await Promise.all([
            getFullAccount(),
            getConfiguredAlerts()
        ]);

        setAppState(state => ({
            ...state,
            maxDataDates,
            user,
            ppgs,
            categories,
            omniCategories,
            company,
            userQuote,
            features,
            defaultRunConfig,
            subscribedSkus,
            favorites,
            nsCompany,
            customCharacteristics,
            categoriesEditable,
            subscription,
            admins,
            dodPresets: extractPresets,
            downloadableProducts,
            brands,
            brandNotRetailingYet,
            preSales,
            canEditCategories,
            viewedTips,
            accessibleMasterCompanies,
            unacceptedCompanyQuoteId,
            configuredAlerts
        }));
        ProductService.setAccessLevel(company.accessLevel);
        // messages is WIP for multi-tenant support
        // unsubscribeFromTenantMessages = subscribeToTenantMessages(user?.authorizedUserId!, tenantId, messagesCollection);
        unsubscribeFromTenantEvents = subscribeToTenantEvents(user?.authorizedUserId!, tenantId);
        setAllPpgs(ppgs);
        fetchUpcDescriptions(defaultRunConfig);
    }

    function markAppInitialized() {
        setAppState(state => ({
            ...state,
            initialized: true
        }));
    }

    function changeTenant(tenantId: string): void {
        localStorage.tenantId = tenantId;

        setAppState(state => ({
            ...state,
            tenantId,
            loadingTenantData: true
        }))
    };

    async function refreshUser(newUserData?: Partial<TenantUser>): Promise<void> {
        const user = newUserData ?? await getUser(true);
        setAppState(state => ({
            ...state,
            user: {
                ...state.user,
                ...user
            }
        }))
    }

    async function refreshAccount() {
        const [
            {
                maxDataDates,
                categories,
                omniCategories,
                ppgs,
                user,
                company,
                userQuote,
                features,
                defaultRunConfig,
                subscribedSkus,
                subscription,
                favorites,
                nsCompany,
                customCharacteristics,
                categoriesEditable,
                admins,
                downloadableProducts,
                brands,
                brandNotRetailingYet,
                unacceptedCompanyQuoteId
            },
            configuredAlerts
        ] = await Promise.all([
            getAccount(true),
            getConfiguredAlerts()
        ]);

        setAppState((state) => ({
            ...state,
            maxDataDates,
            user,
            ppgs,
            categories,
            omniCategories,
            company,
            userQuote,
            features,
            defaultRunConfig,
            subscribedSkus,
            favorites,
            nsCompany,
            customCharacteristics,
            categoriesEditable,
            subscription,
            admins,
            downloadableProducts,
            brands,
            brandNotRetailingYet,
            unacceptedCompanyQuoteId,
            configuredAlerts
        }));
        fetchUpcDescriptions(defaultRunConfig);
    }

    function refreshCompanyBrandInfo(brandInfo: {brandNotRetailingYet?: boolean, brands?: string[], categories?: string[]}): void {
        const {brands, brandNotRetailingYet, categories} = brandInfo;
        setAppState(state => ({
            ...state,
            ...(brands ? {brands} : {}),
            ...(brandNotRetailingYet !== undefined ? {brandNotRetailingYet} : {}),
            ...(categories ? {categories} : {})
        }))
    }

    function refreshUserQuote(userQuote: QuoteResponseType) {
        setAppState(state => ({
            ...state,
            userQuote
        }))
    }

    async function refreshConfiguredAlerts(configuredAlerts?: AppContextValue['configuredAlerts']) {
        const newConfiguredAlerts = configuredAlerts ?? await getConfiguredAlerts();
        setAppState(state => ({
            ...state,
            configuredAlerts: newConfiguredAlerts
        }));
    }

    async function refreshDefaultRunConfig(newDefaultRunConfig?: DefaultRunConfig) { // TODO: Replace this with firestore events listener&data
        const defaultRunConfig = newDefaultRunConfig ?? await getDefaultRunConfig();
        setAppState(state => ({
            ...state,
            defaultRunConfig
        }))
        fetchUpcDescriptions(defaultRunConfig);
    }

    function fetchUpcDescriptions(defaultRunConfig: DefaultRunConfig) {
        if (defaultRunConfig?.characteristics) {
            const upcCharacteristic = defaultRunConfig.characteristics.find((char) => char.characteristic === 'upc');
            const currentRunConfigUpcDescription = appStateRef.current?.runConfigUpcDescription || [];

            const isAllUpcValuesPresent = upcCharacteristic?.value.every((upcValue) =>
                currentRunConfigUpcDescription?.some((u) => u.value === upcValue)
            );

            if (upcCharacteristic && upcCharacteristic.value.length > 0 && !isAllUpcValuesPresent) {

                getUpcDescriptionByCode({
                    upcs: upcCharacteristic.value,
                })
                    .then((upcs) => {
                        const upcDescriptions = upcs.results
                            .filter(({ upc }) => upcCharacteristic.value.includes(upc))
                            .map(({ upc, description }) => ({
                                text: `${upc} ${description}`,
                                value: upc,
                            }));

                        setAppState((state) => ({
                            ...state,
                            runConfigUpcDescription: upcDescriptions,
                        }));
                    })
                    .catch((error) => {
                        console.error('Error fetching UPC descriptions:', error);
                    });
            }
        }
    }

    function setCategories(categories: string[]): void { // TODO: Replace this with firestore events listener&data
        setAppState(state => ({
            ...state,
            categories
        }))
    }

    /**
     * Configures the necessary values in local storage
     * and necessary headers in apis for obo mode
     * if and only if the logged in user is a obo
     */
    async function configCsrObo() {
        // Initially setting request headers for csrOBO
        setOboUserInRequestHeaders();
        const { isObo, csrAccessRoles } = await getIsCsrObo();
        const role = localStorage.getItem('byzzeroboRole')
        setAppState(state => ({
            ...state,
            isCsrObo: isObo,
            oboRole: role,
            csrOboAccessRoles: csrAccessRoles
        }));
        if (!isObo) {
            // At this point we know that the logged in user is not obo
            // hence completely clearing the obo details
            clearOboUserInfo();
        }
        return isObo;
    }


    async function reloadSubscription(): Promise<void> {

        try {
            const {features, includedSkus: subscribedSkus, ...subscription} = await getMySubscription();
            setAppState(state => ({
                ...state,
                subscription,
                features,
                subscribedSkus
            }));

        } catch (err) {
            console.error(err);
            // ignore for now but we should probably do something with this information
        }
    }

    async function reloadDownloadableProducts(): Promise<void> {

        try {
            const {downloadableProducts} = await getMyDownloadableProducts();
            setAppState(state => ({
                ...state,
                downloadableProducts
            }));
        } catch (err) {
            console.error(err);
            // ignore for now but we should probably do something with this information
        }
    }


    async function loadSharedData(): Promise<void> {
        try {
            const [
                allProducts, allMarkets, allDatasets, demographicOptions, reportFilterOptions, personas
            ] = await Promise.all([ // hierarchyTree
                getAllProducts(),
                getAllMarkets(),
                getAllDatasets(),
                getAllDemographicOptions(),
                getAllFilterOptions(),
                getAllPersonas()
                // getCategoryHierarchyTree()
            ]);

            setAppState(state => ({
                ...state,
                demographicOptions,
                allDatasets,
                allProducts,
                allMarkets,
                reportFilterOptions,
                personas
                // hierarchyTree
            }));
            ProductService.addProducts(allProducts);
        } catch (err) {
            console.group('all products failed');
            console.error(err);
            console.groupEnd();
            // ignore for now but we should probably do something with this information
        }
    }

    function subscribeToCommonEvents() {
        try {
            return firebase.firestore().collection('events')
                .where('target', 'in', ['$all', '$system'])
                .where('timestamp', '>', new Date())
                .orderBy('timestamp', 'desc')
                .onSnapshot({
                    next: (snapshot) => {
                        const events = snapshot.docChanges()
                            .filter(({type}) => type === 'added')
                            .map(({doc}) => doc.data());
                        // @ts-ignore - this avoids a TS complaint that the type from snapshot.docChanges() doesnt match our event type.
                        setAppState(state => ({
                            ...state,
                            events
                        }))
                    },
                    error: (err) => {
                        console.error('common event failed 1', err);
                    },
                });

        } catch (err) {
            console.error('common event failed 2', err);
        }
    }

    function subscribeToEmailEvents() {
        try {
            const fbUser = firebase.auth().currentUser;
            return firebase.firestore().collection('events')
                .where('targetType', '==', 'email')
                .where('target', '==', fbUser?.email)
                .where('timestamp', '>', new Date())
                .orderBy('timestamp', 'desc')
                .onSnapshot({
                    next: (snapshot) => {
                        const events = snapshot.docChanges()
                            .filter(({type}) => type === 'added')
                            .map(({doc}) => doc.data());
                        // @ts-ignore - this avoids a TS complaint that the type from snapshot.docChanges() doesnt match our event type.  withConverter might resolve this
                        setAppState(state => ({
                            ...state,
                            events
                        }))
                    },
                    error: (err) => {
                        console.error('common event failed 1', err);
                    },
                });

        } catch (err) {
            console.error('common event failed 2', err);
        }
    }

    function subscribeToTenantEvents(authorizedUserId: string, tenantId: string) {
        if (!authorizedUserId || !tenantId) {
            return
        }
        try {
            return firebase.firestore().collection('events')
                .where('targetType', 'in', ['tenant', 'user'])
                .where('target', 'in', [authorizedUserId, tenantId])
                .where('timestamp', '>', new Date())
                .orderBy('timestamp', 'desc')
                .onSnapshot({
                    next: (snapshot) => {
                        const events = snapshot.docChanges()
                            .filter(({type}) => type === 'added')
                            .map(({doc}) => doc.data())
                        // @ts-ignore - this ignores a TS complaint that the type from snapshot.docChanges() doesnt match our event type
                        setAppState(state => ({
                            ...state,
                            events
                        }))

                    },
                    error: (err) => {
                        console.error('tenant event failed 1', err);
                    },
                });

        } catch (err) {
            console.error('tenant event failed 2', err);
        }
    }

    function subscribeToCommonMessages() {
        try {
            const fbUser = firebase.auth().currentUser;
            const email = fbUser?.email;
            return firebase.firestore().collection('messages')
                .where('to', 'in', ['$system', '$all', email])
                .orderBy('timestamp', 'desc')
                .onSnapshot((snapshot) => {
                    const messages = snapshot.docs.map((doc) => {
                        const message = doc.data();

                        return message;
                    });
                    setAppState(state => ({
                        ...state,
                        messages
                    }))
                });
        } catch (err) {
            console.error('common messages failed', err);
        }
    }

    function subscribeToTenantMessages(authorizedUserId: string, tenantId: string, messagesCollection) {
        try {

            return messagesCollection
                .where('to', 'in', ['tenant', 'user'])
                .where('target', 'in', [authorizedUserId, tenantId])
                .orderBy('timestamp', 'desc')
                .onSnapshot((snapshot) => {
                    const messages = snapshot.docs.map((doc) => {
                        const message = doc.data();

                        return message;
                    });
                    setAppState(state => ({
                        ...state,
                        messages
                    }))
                });
        } catch (err) {
            console.error('tenant messages failed', err);
        }
    }

    async function refreshNotifications(): Promise<void> {
        try {
            let notifications = await getMyNotifications();
            let activeNotifications;
            if (notifications.length) {
                activeNotifications = notifications.filter((item) => {
                    return (item?.isRead === null || !item?.isRead) && item?.messageType.toUpperCase() !== "RECOMMENDATION"
                })
            }
            setAppState((state) => ({
                ...state,
                notifications,
                activeNotifications
            }));
        } catch (err) {
            console.log('Notification', err);
        }
    }

    // This function is added to retrieve all saved selections of DOD.
    async function reloadDodPresets(): Promise<void> {

        const dodPresets = await getDodPresets();

        setAppState((state) => ({
            ...state,
            dodPresets
        }));
    }

    function updateOboAllowAccess(oboFlag: boolean) {
        setAppState(state => ({
            ...state,
            oboAllowAccessWhenTcsPending: oboFlag,
        }))
    }

    return (
        <AppContext.Provider value={appState}>
            {children}
        </AppContext.Provider>
    );
};